<!--
 * @Author: your name
 * @Date: 2021-03-29 13:47:56
 * @LastEditTime: 2021-05-19 12:37:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /smart/src/components/layouts/siderMenu.vue
-->
<template>
  <!-- <div class="sider_menu"> -->
  <el-col :span="12">
    <el-menu
      router
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      @open="handleOpen"
      @close="handleClose"
      background-color="#fff"
      text-color="#000000"
      active-text-color="#189F92"
    >
      <template v-for="(m, index) in menuData">
        <template v-if="m.children && m.children.length === 1">
          <el-menu-item
            :index="m.children[0].path"
            :key="'menu-' + index + '-' + m.name"
          >
            <i :class="m.meta.icon"></i>
            <span :slot="m.name">{{ m.meta.title }}</span>
          </el-menu-item>
        </template>
        <template v-else>
          <el-submenu
            :index="'menu-' + index"
            :key="'menu-' + index + '-' + m.name"
          >
            <template slot="title">
              <i :class="m.meta.icon"></i>
              <span>{{ m.meta.title }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="(children, idx) in m.children">
                <el-menu-item
                  
                  :index="children.path"
                  v-if="!children.hidden"
                  :key="'subMenu' + idx + '-' + children.name"
                  >{{ children.meta.title }}</el-menu-item
                >
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </el-col>
  <!-- </div> -->
</template>

<script>
// import { insert } from '@/api/index.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    const menuData = this.getMenuData(this.$router.options.routes)
    return {
      menuData,
      operateContent: '',
    }
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
  mounted() {
    // this.$route.path()
    // console.log('$route.path', this.$route.path)
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    getMenuData(routes) {
      console.log('routes', routes)
      const menuData = []
      // const currUserMenu = JSON.parse(window.localStorage.getItem('menuList'))
      routes.forEach(item => {
        if (!item.hidden) {
          menuData.push(item)
          // item.children.forEach(children => {
          //   menuData.push(children)
          // })
        }
      })
      // console.log('menuData', menuData[0].children[0].path)
      return menuData
    },
  },
}
</script>

<style lang="scss" scoped>
.el-col-12 {
  width: 200px;
  height: 100%;
  position: fixed;
  // top: 50px;
  top: 6vh;
  left: 0;
  z-index: 999;
}
.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
}
.el-menu {
  height: 100%;
  border-right: none !important;
}
.el-menu:not(.el-menu--collapse) {
  width: 100%;
}
.menu_icon {
  margin: 2% 6% 2% 2%;
}
.el-menu-item i,
// .el-submenu__title i {
//   color: #fff;
// }
::v-deep .el-submenu .el-menu-item{
  min-width: 0;
}
.el-menu--collapse {
  width: 44px;
}
.el-menu-item{
  // padding-left: 10px !important;
}
// .el-menu--collapse>.el-submenu__title{
//   padding-left: 10px !important;
// }
/deep/.el-submenu__title {
  // padding-left: 10px !important;
}
</style>
