<!--
 * @Author: your name
 * @Date: 2021-03-29 13:45:31
 * @LastEditTime: 2021-05-17 09:35:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /smart/src/components/layouts/basicLayout.vue
-->
<template>
  <div class="basic_layout">
    <Header></Header>
    <div :class="isCollapse ? 'body2': 'body'">
      <sider-menu></sider-menu>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index'
import SiderMenu from './siderMenu.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Header,
    SiderMenu,
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
}
</script>

<style lang="scss" scoped>
.basic_layout {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
.body {
  // background: url(../../assets/bg1.png);
  // background-size: 100% 100%;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  // padding-top: 50px;
  padding-top: 6vh;
  padding-left: 200px;
  box-sizing: border-box;
  background-color: #f1f2f6;
  overflow: hidden;
  transition-property: padding-left;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
}
.body2 {
  // background: url(../../assets/bg1.png);
  // background-size: 100% 100%;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  // padding-top: 50px;
  padding-top: 6vh;
  padding-left: 44px;
  box-sizing: border-box;
  background-color: #f1f2f6;
  overflow: hidden;
  transition-property: padding-left;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease-in-out;
}

</style>
