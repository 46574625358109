<!--
 * @Author: your name
 * @Date: 2021-03-29 13:46:55
 * @LastEditTime: 2021-05-12 09:18:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /smart/src/components/layouts/header.vue
-->
<template>
  <div class="header">
    <!-- <div style="display: flex;margin: 0.15rem 0.2rem 0rem;">
      <i class="el-icon-s-fold white" v-if="!isCollapse" @click="putIsCollapse(true)"></i>
      <i class="el-icon-s-unfold white" v-else @click="putIsCollapse(false)"></i>
    </div> -->
    <div class="title">
      <!-- <span>管理系统</span> -->
      <!-- <span class="time">{{ newDate }}</span> -->
      <span class="time">健康证后台管理系统</span>
    </div>
    <!-- <div class="right">
      <span>欢迎登录：</span>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ this.userName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
  </div>
</template>

<script>
// import { updatePassWord, logout } from '@/api/index'
import screenfull from 'screenfull'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      newDate: new Date(),
      userName: localStorage.getItem('userName')
      // isCollapse: true
    }
  },
  mounted() {
    // this.isCollapse = this.$store.isCollapse
    let that = this
    this.timer = setInterval(function () {
      that.newDate = new Date().toLocaleString()
    })
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
  methods: {
    putIsCollapse(val) {
      this.$store.commit('setIsCollapse', val)
    },
    fullScreen() {
      if (screenfull.isEnabled) {
        if (!screenfull.isFullscreen) {
          screenfull.request();
        } else {
          screenfull.exit();
        }
      }
    },
    handleCommand(command) {
      if (command === 'logOut') {
        this.loginOut()
      } else if (command === 'updatePassWord') {
        this.dialogTableVisible = true
        this.changeImgCode()
      }
    },
    loginOut() {
      this.$router.push({ path: '/login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.white {
  color: #fff;
  font-size: 30px;
}
.header {
  width: 100%;
  // height: 50px;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 2%;
  // background: linear-gradient(
  //   90deg,
  //   rgba(63, 180, 248, 1) 0%,
  //   rgba(63, 180, 248, 1) 0%,
  //   rgba(47, 136, 255, 1) 100%,
  //   rgba(47, 136, 255, 1) 100%
  // );
  background-color: #189F92;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.left {
  width: 250px;
  display: flex;
  .header_left {
    width: 100%;
  }
}
.title {
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :first-child {
    padding: 0 1%;
  }
  span {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }
}
.time {
  // margin-left: 50px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.right {
  width: 100%;
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // img {
  //   margin: 0 2%;
  // }
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.validate_item {
  /deep/.el-form-item__content {
    display: flex;
  }
}
/deep/.validateCode_inp .el-input {
  width: 70%;
}
</style>
